import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ComicReader from "../components/comic-reader"
import ComicItemsList from "../components/comic-items-list"
import _ from "lodash"
import ComicItemInfo from "../components/comic-item-info"
import Wide3DHeader from "../components/headers/wide-3d-header"
import Figure from "react-bootstrap/Figure"
import GatsbyImage from "gatsby-image"
import { css } from "@emotion/core"
import ContentBox from "../components/content-box"
import PageHeader from "../components/headers/page-header"
import TapeLabelHeader from "../components/headers/tape-label-header"
import SectionHeader from "../components/headers/section-header"

class ComicPageTemplate extends React.Component {
  render() {

    // TODO: get comic

    const series = this.props.data.markdownRemark
    const issues = this.props.data.issues.edges
    const allSeriesPages = this.props.data.allSeriesPages.edges
    const conceptArt = this.props.data.conceptArt.edges
    const pages = this.props.data.pages.edges

    // TODO: get pages grouped per issue (or group them manually)

    const firstIssue = issues[0] ? issues[0].node : { frontmatter: { title: "UNPUBLISHED" } }

    const publishedComics = this.props.data.publishedComics.edges


    // get current page position in pages
    let currentIssueIndex = _.findIndex(issues, { node: { frontmatter: { title: firstIssue.frontmatter.title } } })

    let nextIssue = null
    if (issues.length && currentIssueIndex < issues.length - 1) {
      nextIssue = issues[currentIssueIndex + 1].node
    } else {
      if (series.frontmatter.anthology && currentIssueIndex !== 0) {
        nextIssue = issues[0].node
      }
    }

    let prevIssue = null
    if (currentIssueIndex > 0) {
      prevIssue = issues[currentIssueIndex - 1].node
    } else {
      if (issues.length && series.frontmatter.anthology && issues.length !== 1) {
        prevIssue = issues[issues.length - 1].node
      }
    }

    return (
      <Layout
        location={this.props.location}
        svg={series.frontmatter.svg.publicURL}
      >

        <SEO
          title={series.frontmatter.title}
          description={series.frontmatter.description || series.excerpt}
          ogImageUrl={firstIssue.frontmatter.image.childImageSharp.fluid.src}
          comic={series}
          url={this.props.location.href}
          issue={allSeriesPages.length && allSeriesPages.length > 0 && firstIssue}
        />

        {(series.frontmatter.published !== false || (allSeriesPages.length && allSeriesPages.length > 0)) ?
        <ComicReader
          nextIssue={nextIssue}
          previousIssue={prevIssue}

          comic={series}
          issue={firstIssue}
          latestPage={allSeriesPages[0] && allSeriesPages[allSeriesPages.length - 1].node}
          nextPage={pages[0] && pages[0].node}/> : null

        }

        <PageHeader>{series.frontmatter.title}</PageHeader>
        {series.frontmatter.published === false && <SectionHeader>(Coming Soon!)</SectionHeader>}

        <ComicItemInfo html={series.html} image={series.frontmatter.promo || series.frontmatter.image}
                       title={`About this series`} credits={series.frontmatter.credits}/>

        <ComicItemInfo html={firstIssue.html} image={firstIssue.frontmatter.image} link={firstIssue.fields.slug}
                       title={`About the first issue`} credits={firstIssue.frontmatter.credits}/>


        {series.frontmatter.published !== false && <div>
          <Wide3DHeader>Issues in this Series</Wide3DHeader>
          <ComicItemsList items={issues} loop={false}/></div>}

        {conceptArt.length > 0 && <div><Wide3DHeader>Concept Art</Wide3DHeader>
          <ContentBox>
            <div
              css={css`
              display: flex;
              flex-flow: row wrap;
              margin-left: -8px; /* Adjustment for the gutter */
              width: 100%;
            
            > div {
              flex: auto;
              height: 350px;
              min-width: 150px;
              margin: 0 8px 8px 0; /* Some gutter */
              text-align: center;
            }
          `}>
              {_.map(conceptArt, ({ node }) => {
                return (
                  <div>
                    <GatsbyImage
                      fixed={node.frontmatter.image.thumb.fixed}
                      alt={node.frontmatter.title}
                      css={css`max-width: 100%;`}
                    />
                    <div>{node.frontmatter.title} by {node.frontmatter.credits[0].name.name}</div>
                  </div>
                )
              })}
            </div>
          </ContentBox></div>}

        <Wide3DHeader>Other Comic Series</Wide3DHeader>
        <ComicItemsList items={publishedComics} exclude={[series]}/>

      </Layout>
    )
  }
}

export default ComicPageTemplate

export const pageQuery = graphql`
    query ComicPageBySlug($slug: String!, $id: String!) {

        # Comic Series
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            fields { slug }
            ...ComicSeriesFrontmatter
        }

        # Comic Series Pages
        pages: allMarkdownRemark(sort: {order: ASC, fields: frontmatter___seriesPage}, filter: {frontmatter: {type: {eq: "comic-page"},  comic: {eq: $id} }}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    ...ComicPageOptimizedFrontmatter
                }
            }
        }

        allSeriesPages: allMarkdownRemark(sort: {order: ASC, fields: frontmatter___seriesPage}, filter: {frontmatter: {type: {eq: "comic-page"},  comic: {eq: $id} }}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    ...ComicPageOptimizedFrontmatter
                }
            }
        }

        # Comic Series Issues
        issues: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic-issue"}, comic: {eq: $id}}}, sort: {fields: frontmatter___date, order: ASC}) {
            edges {
                node {
                    id
                    html
                    fields {
                        slug
                    }
                    ...ComicIssueFrontmatter
                }
            }
        }

        # Published Comics Series
        publishedComics: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic"}}}) {
            edges {
                node {
                    id
                    fields {slug }
                    ...ComicSeriesFrontmatter
                }
            }
        }

        # Concept Art
        conceptArt: allMarkdownRemark(sort: {order: ASC, fields: fileAbsolutePath}, filter: {frontmatter: {comic: {eq: $id}, type: {eq: "concept-art"}}}) {
            edges {
                node {
                    ...ConceptArtFrontmatter
                }
            }
        }

    }
`
